import { AuthLayout } from '../components/Layouts';
import { Paper, Typography, Box, Button } from '@mui/material';
import { Download } from '@mui/icons-material';
import React from 'react';
import { LoginForm } from '../components/features/auth/LoginForm';

const Login = () => {
  return (
    <Box>
      <Typography
        mb="24px"
        variant="h1"
        component="h3"
        sx={{ textAlign: 'center' }}
      >
        Catalog Talk
      </Typography>
      <Paper elevation={1}>
        <Box sx={{ paddingX: 4, paddingY: 5 }}>
          <LoginForm />
        </Box>
      </Paper>
      <Box sx={{ marginTop: 4 }}>
        <Typography color="info" variant="h6" component="h2">
          Catalog Talkへようこそ 【推奨ブラウザ: Google Chrome】
        </Typography>
        <Typography color="info" mb={'1.5rem'}>
          Google Chrome
          をご利用でない方は下記のボタンからダウンロードを進めてください
        </Typography>
        <Button
          href="https://www.google.com/chrome/"
          size="small"
          startIcon={<Download />}
          fullWidth
          color="secondary"
        >
          最新の Google Chrome をダウンロード
        </Button>
      </Box>
    </Box>
  );
};

Login.getLayout = function getLayout(page: React.ReactElement) {
  return <AuthLayout>{page}</AuthLayout>;
};

export default Login;

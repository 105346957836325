import React, { useState } from 'react';
import {
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Typography
} from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LOGIN_ERROR_MESSAGES, useAuth } from 'hooks/useAuth';
import * as Sentry from '@sentry/nextjs';

type FormValues = {
  email: string;
  password: string;
  accept_policy: boolean;
};

const schema = yup
  .object({
    email: yup
      .string()
      .required('メールアドレスは必須項目です')
      .email('@が含まれた有効なメールアドレスを入力してください'),
    password: yup.string().required('パスワードは必須項目です'),
    accept_policy: yup.boolean().oneOf([true], 'チェックが必要です')
  })
  .required();

const LoginForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid }
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      accept_policy: false
    }
  });
  const { login } = useAuth();
  const [error, setError] = useState<string>('');
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  const onSubmit: SubmitHandler<FormValues> = async ({ email, password }) => {
    setIsLoggingIn(true);
    await login(email, password).catch((e) => {
      if (e.message === LOGIN_ERROR_MESSAGES.LOGIN_FAILED) {
        setError(
          'ログインに失敗しました。メールアドレスまたはパスワードが間違っています。'
        );
        setIsLoggingIn(false);
        return;
      }
      Sentry.captureException(e);
      setError('ログインに失敗しました。');
      setIsLoggingIn(false);
      return;
    });
    Sentry.setUser({ email: email });
    // isLoggedIn();
  };

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
      component="form"
    >
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            required
            fullWidth
            label="メールアドレス"
            placeholder="user@swoda.co.jp"
            variant="outlined"
            className="textField"
            error={!!errors.email?.message}
            helperText={errors.email?.message ?? ''}
            {...field}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            required
            fullWidth
            label="パスワード"
            type="password"
            variant="outlined"
            className="textField"
            error={!!errors.password?.message}
            helperText={errors.password?.message ?? ''}
            sx={{
              marginTop: 4
            }}
            {...field}
          />
        )}
      />
      <Controller
        name="accept_policy"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} required />}
            label={
              <Typography>
                <a
                  href="https://catalog-talk.com/d/terms-of-use.html"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  利用規約
                </a>
                に同意します
              </Typography>
            }
          />
        )}
      />
      {errors.accept_policy && (
        <Typography variant="caption" color="error">
          {errors.accept_policy.message}
        </Typography>
      )}
      {error !== '' && (
        <Typography
          mt="1rem"
          variant="caption"
          color="error"
          textAlign="center"
        >
          {error}
        </Typography>
      )}
      <Button
        color="secondary"
        size="large"
        type="submit"
        variant="contained"
        sx={{
          marginTop: 6
        }}
        disabled={!isValid || isLoggingIn}
      >
        {isLoggingIn ? 'ログインしています…' : 'ログイン'}
      </Button>
    </Box>
  );
};

export default LoginForm;
